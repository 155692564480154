import { shallowEqual, useSelector } from 'react-redux';
import { SearchAction, WebSite, WithContext } from 'schema-dts';

import {
  selectDomainConfig,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { domainGenerator } from 'utils/urlGenerator';

/**
 * Хук для получения item для WebSite
 */
export const useItemForWebSite = () => {
  const domainConfig = useSelector(selectDomainConfig, shallowEqual);
  const projectId = useSelector(selectProjectId);

  const domain = domainGenerator(domainConfig, projectId);

  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: domain,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${domain}/search/?query={search_term_string}`,
      'query-input': 'required name=search_term_string',
    } as SearchAction,
  } as WithContext<WebSite>;
};
