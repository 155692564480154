import cn from 'classnames';
import React, { useId } from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useCounterId } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

import { useLoadRamblerEmbed } from '../hooks/useLoadRamblerEmbed';
import s from '../styles.module.css';

import styles from './styles.module.css';

const PARENT_ID = 'rambler-calendar-container';
const WIDGET_ID = 'rambler-calendar';

type RamblerCalendarProps = {
  className?: string;
};

/**
 * Компонент с ембедом Рамблера - Календарь.
 */
export const RamblerCalendar = withErrorBoundary(function RamblerWeather({
  className,
}: RamblerCalendarProps) {
  const id = useId();
  const yandexCounterId = useCounterId(COUNTER_ID.WebMobile);

  const { embedRef, isInited } = useLoadRamblerEmbed({
    parentId: `${PARENT_ID}${id}`,
    widgetId: `${WIDGET_ID}${id}`,
    url: 'https://embeds.rambler.ru/calendar/calendar.js',
  });

  return (
    <div
      id={`${PARENT_ID}${id}`}
      className={cn(className, styles.root, isInited ? '' : s.placeholder)}
    >
      <rambler-calendar ref={embedRef} yandex-metrica-id={yandexCounterId} />
    </div>
  );
});
