import cn from 'classnames';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'common/redux/runtime/selectors';
import { FONTS, getLoadFont } from 'utils/fonts/getLoadFont';

import s from './styles.module.css';

export type DataTitleType = {
  name: string | undefined;
  title?: string;
};

type CommonTitlePropsType = {
  dataTitle: DataTitleType;
  hidden?: boolean;
};

/**
 * Заголовок для страницы.
 * @param dataTitle - данные для заголовка;
 * @param hidden - флаг что заголовки скрыты и отображаются только для сео и скринридеров.
 */
export const CommonTitle = memo(
  ({ dataTitle, hidden }: CommonTitlePropsType) => {
    const isMobile = useSelector(selectIsMobile);

    const titleFont = getLoadFont(FONTS.manropeExtraBold);

    return (
      <div
        className={cn(s.container, isMobile && s.mobile, hidden && s.hidden)}
      >
        <h1 className={cn(s.title, titleFont)}>{dataTitle.name || ''}</h1>
        {dataTitle.title && <p className={s.text}>{dataTitle.title}</p>}
      </div>
    );
  },
);
