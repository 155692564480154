import React, { memo } from 'react';
import { WebSite } from 'schema-dts';

import { JsonLd } from 'utils/jsond';

import { useItemForWebSite } from './hooks';

/**
 * Разметка WebSite с SearchAction
 * https://developers.google.com/search/docs/data-types/sitelinks-searchbox
 *
 */
export const WebSiteSchema = memo(() => {
  const item = useItemForWebSite();

  return <JsonLd<WebSite> item={item} />;
});
