import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Head } from 'common/components/headers/Head';
import { PreloadMainImage } from 'common/components/headers/PreloadMainImage';
import { ItemListSchema } from 'common/components/schemaOrg/ItemList';
import {
  selectRegionPageClusterInCardData,
  selectRegionPageItem,
} from 'common/redux/pages/region/selectors';
import { selectIsMobile } from 'common/redux/runtime/selectors';
import { useHeader } from 'desktop/hooks/useHeader';
import { textCroppingByWord } from 'utils/textCroppingByWord';

import { getDescriptionString } from '../utils';

/**
 * Мета страницы региона.
 */
export const RegionHeader = memo(() => {
  const isMobile = useSelector(selectIsMobile);
  const region = useSelector(selectRegionPageItem, shallowEqual);

  const { clustersBySchema } = useHeader(selectRegionPageClusterInCardData);

  const pageTitle = `Новости ${region?.namer} сегодня`;

  const str = getDescriptionString({ title: region?.name, clustersBySchema });

  const pageDescription = textCroppingByWord({ str });

  const pageKeywords = `${
    region?.name
  }, новости, сегодня, ${new Date().getFullYear()}, последние, свежие`;

  return (
    <>
      <PreloadMainImage image={clustersBySchema[0]?.image} />
      <Head
        top100PageType="region"
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageKeywords={pageKeywords}
        pageUrl={`/${region?.alias}/`}
        rssTitle={region?.name}
        rssUrl={`/rss/${region?.alias}/?limit=100`}
        isMobile={isMobile}
        data-test="region"
      />
      <ItemListSchema items={clustersBySchema} />
    </>
  );
});
